import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import Header from "../include/header";
import Sidemenu from "../include/side-manu";
import Table from "react-bootstrap/Table";

import { Link, useNavigate } from "react-router-dom";
import { ApiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import { Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { colors } from "@mui/material";
import { Colors } from "chart.js";
import moment from 'moment'

// import ApexCharts from 'apexcharts'

function Dashboard() {
  /* New Code Start  */
  const [userGlobal, setUserGlobal] = useState([]);

  const [countryRevenueUsers,  setCountryRevenueUsers] = useState();

  const [selectedCategory, setSelectedCategory] = useState("subscription"); 
  const colors = [
    "rgba(105, 162, 39, 0.2)",
    "rgba(105, 162, 39, 0.2)",
    "rgba(105, 162, 39, 1)",
    "rgba(105, 162, 39, 0.2)",
    "rgba(105, 162, 39, 0.2)",
    "rgba(105, 162, 39, 0.2)",
    "rgba(105, 162, 39, 0.2)",
    "rgba(105, 162, 39, 0.2)",
  ];


  const [chartData, setChartData] = useState({
    series: [{ name: "Revenue", data: [] }],
    
     options : {
      chart: {
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      series: [
        {
          name: "Chart Revenue",
          data: [70, 80, 150, 50, 79, 100, 80, 90, 45],
        },
      ],
      xaxis: {
        categories: [
          ["Jan"],
          ["Feb"],
          ["Mar"],
          ["Apr"],
          ["May"],
          ["Jun"],
          ["July"],
          ["Aug"],
          ["Sept"],
        ],
        labels: {
          style: {
            colors: "#000",
            fontSize: "12px",
          },
        },
      },
    }
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedYear, setSelectedYear] = useState(2025);




  /* New Code End  */



  const [userShow, setuserShow] = useState(false);
  const [subsShow, setsubsShow] = useState(false);

  const navigate = useNavigate();

  const [userData, setUserData] = useState([]);

  const [country, setCountry] = useState([]);

  const [subscriptions, setSubscriptions] = useState([]);

  const [countryCopy, setCountryCopy] = useState([]);

  /* Country Search */
  const searchCountry = (searchKey) => {
    let data = [];
    countryCopy.map((search) => {
      if (search?.country.toLowerCase().includes(searchKey?.toLowerCase())) {
        data.push(search);
      }
    });
    setCountry(data);
  };



  useEffect(() => {
    if (userShow == false || subsShow == false) {
      // fetchData();
    }
  }, [userShow, subsShow]);

  const fatchUsersGlobally = async (req, res) => {
    try {
      const { data, message, success } = await ApiRequest({
        endUrl: API_END_URLS.userGlobally,
        method: "GET",
      });
      setUserGlobal(data || [])
    } catch (error) {
      console.log("🚀 ~ fatchUsersGlobally ~ error:", error);
    }
  };


  const FatchSubscribersGlobally = async(req, res) =>{
    try{
      const { data, message, success } = await ApiRequest({
            endUrl: API_END_URLS.userSubscriberGlobally,
            method: "get",
          });
          if(success){
            setSubscriptions(data);
          }
    }catch(error){
      showMessage(error);
    }
  }


  const fatchSubscribersRevenueGlobally = async(req, res) =>{
    try{
      const { data, message, success } = await ApiRequest({
            endUrl: API_END_URLS.userSubscriberRevenueGlobally,
            method: "get",
          });
          if(success){
            setCountryRevenueUsers(data);
          }
    }catch(error){
      showMessage(error);
    }
  }



  const handleCountryClick = async (country) => {
    try {
      navigate('/users', { state: { country } });
    } catch (error) {
        console.log("API Request Failed:", error);
    }
  };

  const handleSubsciberGlobally = async (country) =>{
    try{
      navigate('/subsglobally', { state: { country } });
    }catch(error){
      console.log("API Request Failed:", error);
    }
  }

  const handleSubsciberRevenueGlobally = async (country) =>{
    try{
      navigate('/revenglobally', { state: { country } });
    }catch(error){
      console.log("API Request Failed:", error);
    }
  }


  useEffect(() => {
    fatchUsersGlobally();
    FatchSubscribersGlobally();
    fatchSubscribersRevenueGlobally();
  }, []);



    const fetchRevenueData = async () => {
      setLoading(true);
      setError("");
  
      try {
        const { data, message, success } = await ApiRequest({
          endUrl: API_END_URLS.userRevenueGraph,
          method: "get",
          query: { year: selectedYear, category: selectedCategory },
        });
  
        if (!success) {
          throw new Error(message || "Failed to fetch revenue data.");
        }
  
        const revenueData = data || [];
        const allMonths = [1,2,3,4,5,6,7,8,9,10,11,12]
        let months = [], revenues = []

        allMonths.forEach((month) =>{
          const findData = revenueData.find((item) => item.month === month)
          if(findData){
            months.push(moment(findData?.month, "MM").format("MMM"))
            revenues.push(findData?.total_revenue)
          }else{
            months.push(moment(month, "MM").format("MMM"))
            revenues.push(0)
          }
        });
  
        setChartData({
          series: [{ name: "Revenue", data: revenues }],
          options: { ...chartData.options, xaxis: { categories: months } },
        });
      } catch (err) {
        setError(err.message || "Error fetching revenue data.");
      }
  
      setLoading(false);
    };
  
    useEffect(() => {
      fetchRevenueData();
    }, [selectedYear, selectedCategory]);

      const categories = [
    { key: "subscription", label: "Subscription", icon: "/images/ch-icon-1.png" },
    { key: "advertisement", label: "Advertisement", icon: "/images/ch-icon-2.png" },
    { key: "additional_posting", label: "Additional Posting", icon: "/images/ch-icon-3.png" },
    { key: "promotion", label: "Promotion", icon: "/images/ch-icon-4.png" },
  ];

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div class="head-text mt-3 mt-sm-0">
                  <h1>Total Users Map</h1>
                </div>

                <div class="row mt-3 mt-sm-4">
                  <div class="col-md-4 mb-2">
                    <div class="seller-box">
                      <div class="seller-boxbgimg">
                        <div className="table-responsive">
                          <Table className="seller-table m-0">
                            <thead>
                              <tr>
                                <th colSpan={3}>Users Globally</th>
                              </tr>
                            </thead>
                              <tbody>

                                {userGlobal?.length > 0 ? (
                                 userGlobal.map((user, index) => (
                                  <tr key={index} onClick={() => handleCountryClick(user?.country)} style={{cursor: 'pointer'}}>
                                     <td>
                                          <div className="flag-img">
                                          <img src={user?.flag} alt={`${user?.country} flag`} />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="country-vall">
                                            <p>{user.count}</p>
                                            {user?.country.charAt(0).toUpperCase() + user.country.slice(1).toLowerCase()}
                                          </div>
                                        </td>
                                        <td  style={{ color: user.isPositive ? "green" : "red" }}>
                                          <div className="globality-count green-count">
                                            <img src="/images/green-arrow.svg" alt="Growth Indicator" />
                                            <span>{user.growth}%</span>
                                          </div>
                                        </td>
                                  </tr>
                                ))
                                ): (
                                  <tr>
                                  <td colSpan="2">No data available</td>
                                </tr>
                                )}
                              </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-2">
                    <div class="seller-box">
                      <div class="seller-boxbgimg">
                        <div className="table-responsive">
                          <Table className="seller-table m-0">
                            <thead>
                              <tr>
                                <th colSpan={3}>Subscribers Globally</th>
                              </tr>
                            </thead>
                            {/* <Link to="/subsglobally"> */}
                              <tbody>
                              {subscriptions?.length > 0 ? (
                                    subscriptions.map((subscription, index) => (
                                      <tr key={index} onClick={() => handleSubsciberGlobally(subscription?.country)} style={{cursor: "pointer"}}>
                                        <td>
                                          <div className="flag-img">
                                          <img src={subscription?.flag} alt={`${subscription?.country} flag`} />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="country-vall">
                                            <p>{subscription?.count}</p>
                                            {subscription?.country.charAt(0).toUpperCase() + subscription.country.slice(1).toLowerCase()}
                                          </div>
                                        </td>
                                        <td  style={{ color: subscription.isPositive ? "green" : "red" }}>
                                          <div className="globality-count green-count">
                                            <img src="/images/green-arrow.svg" alt="Growth Indicator" />
                                            <span>{subscription.growth}%</span>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : null}
                              </tbody>
                            {/* </Link> */}
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-2">
                    <div class="seller-box">
                      <div class="seller-boxbgimg">
                        <div className="table-responsive">
                          <Table className="seller-table m-0">
                            <thead>
                              <tr>
                                <th colSpan={3}>
                                  Subscription Revenue Globally
                                </th>
                              </tr>
                            </thead>
                            {/* <Link to="/revenglobally"> */}
                              <tbody>
                              {Array.isArray(countryRevenueUsers) && countryRevenueUsers.length > 0 ? (
                                  countryRevenueUsers.map((countryData, index) => (
                                    <tr key={index} onClick={() =>handleSubsciberRevenueGlobally(countryData?.country) } style={{cursor: "pointer"}}>
                                      <td>
                                        <div className="flag-img">
                                          <img 
                                            src={countryData?.flag || "/images/default-flag.svg"} 
                                            alt={countryData?.country || "Unknown"} 
                                            onError={(e) => e.target.src = "/images/default-flag.svg"} 
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="country-vall">
                                          <p>${parseFloat(countryData?.totalRevenue || 0).toFixed(2)}</p>
                                          {countryData?.country || "Unknown"}
                                        </div>
                                      </td>
                                      <td>
                                        <div className={`globality-count ${countryData?.isPositive ? 'green-count' : 'red-count'}`}>
                                          <img
                                            src={countryData?.isPositive ? "/images/green-arrow.svg" : "/images/red-arrow.svg"}
                                            alt={countryData?.isPositive ? "Growth" : "Decline"}
                                          />
                                          <span>{parseFloat(countryData?.growth || 0).toFixed(1)}%</span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="3" style={{ textAlign: "center", padding: "10px" }}>
                                      No data available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            {/* </Link> */}
                          </Table>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3 mt-sm-4">
                    <div className="chart-tabs position-relative">
                      <div className="chart-head">
                        <h1>Add on Revenue Globally</h1>
                        <p>Yearly Earnings Overview in Dollar</p>
                      </div>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey={selectedCategory}
                      >
                        <div className="row pt-sm-0 pt-5">
                          <div className="col-md-12">
                            <Nav className="justify-content-sm-end justify-content-center d-sm-flex d-block">
                              {categories.map((cat) => (
                                <Nav.Item key={cat.key}>
                                  <Nav.Link eventKey={cat.key} onClick={() => setSelectedCategory(cat.key)}>
                                    <div className="text-center ch-nav-icon">
                                      <img src={cat.icon} alt={cat.label} />
                                      <p>{cat.label}</p>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                            </Nav>
                          </div>
                        </div>
                        <div className="year-selector" style={{color: "gray"}}>
                        <label>Select Year: </label>
                        <select value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))} style={{color: "gray"}}>
                          {Array.from({ length: 6 }, (_, i) => 2025 + i).map((year) => (
                            <option key={year} value={year}>{year}</option>
                          ))}
                        </select>
                      </div>


                        <div className="row mt-sm-3">
                          <div className="col-md-12">
                            <Tab.Content>
                              <Tab.Pane eventKey={selectedCategory}>
                                <div className="row ">
                                  <div className="col-md-10">
                                    <div className="dash-chart">
                                       <ReactApexChart height={350} options={chartData.options} series={chartData.series} type="bar" />
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </div>
                        </div>
                      </Tab.Container>
                    </div>

                    {/* <Nav variant="tabs" defaultActiveKey="/">
                                            <Nav.Item>
                                                <Nav.Link href="/">Active</Nav.Link>
                                            </Nav.Item>
                                          
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-1">Option 2</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="disabled" disabled>
                                                    Disabled
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav> */}
                  </div>

                  {/* <div class="col-md-6">
                                        <div class="seller-box">
                                            <div class="seller-bottomimg">
                                                <div class="row">
                                                    <div class="col-md-12">

                                                        <div class="table-responsive">
                                                            <Table className="seller-table sec-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={4}>Recently Added - Top 5 Users</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {userData.slice(0, 5).map((item, key) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="user-pics">
                                                                                            <img
                                                                                                src={API_END_URLS.S3Bucket + item?.profile_url || "images/table-user1.png"} alt=''
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {item?.name}
                                                                                        <br />
                                                                                        {item?.email}
                                                                                    </td>
                                                                                    <td>{item?.country}</td>
                                                                                    <td>
                                                                                        <span class="badge green-badge">
                                                                                            Standard
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="border-top">
                                                    <h4 style={{ cursor: "pointer" }} onClick={() => navigate('/users')}>
                                                        View Full Stats
                                                    </h4>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="seller-box">
                                            <div class="seller-bottomimglast">
                                                <div class="row">
                                                    <div class="col-md-12">

                                                        <div class="table-responsive">
                                                            <Table className="seller-table sec-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={4}>Most Active - Top 5 Users</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><div class="user-pics"><img src="images/table-user1.png" alt='' /></div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p>  </td>
                                                                        <td>UAE</td>
                                                                        <td><span class="badge green-badge">Standard</span></td>
                                                                    </tr>
                                                                    <tr>

                                                                        <td><div class="user-pics"><img src="images/table-user2.png" alt='' /> </div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p>  </td>
                                                                        <td>India</td>
                                                                        <td><span class="badge red-badge">Premium</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><div class="user-pics"><img src="images/table-user3.png" alt='' /></div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p> </td>
                                                                        <td>Australia</td>
                                                                        <td><span class="badge red-badge">Premium</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><div class="user-pics"><img src="images/table-user4.png" alt='' /></div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p></td>
                                                                        <td>UAE</td>
                                                                        <td><span class="badge green-badge">Standard</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><div class="user-pics"><img src="images/table-user5.png" alt='' /></div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p> </td>
                                                                        <td>UAE</td>
                                                                        <td><span class="badge green-badge">Standard</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="border-top">
                                                    <h4 style={{ cursor: "pointer" }}>View Full Stats</h4>
                                                </div>

                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
                className="new-model"
                size="md"
                show={userShow}
                onHide={() => setuserShow(false)}
                aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton >
                    <h1 className="new-pophead" >Users Globality</h1>
                </Modal.Header>
                <Modal.Body >
                    <div class="globality">
                        <div class="form-group position-relative">
                            <input
                                type="email"
                                class="form-control serch-input"
                                placeholder="Search"
                                onChange={event => searchCountry(event.target.value)}
                            />
                            <img src="images/search-img.png" class="search-img" alt="/" />
                        </div>
                        <div class="country-globe-ranks">
                            <div class="row align-items-center">
                                {
                                    country?.length != 0 ? country?.map((item, key) =>
                                    (
                                        <>
                                            <div class="col-md-8">
                                                <div class="country-ranks">
                                                    <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                    <h2>{item?.country}</h2>
                                                </div>
                                            </div>
                                            <div class="col-md-4 text-right">
                                                <div class="ranks">
                                                    <h1>{item?.count}</h1>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    ) :
                                        <>
                                            <div class="col-md-8">
                                                <div class="country-ranks">
                                                    <h2>Data not found</h2>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                className="new-model"
                size="md"
                show={subsShow}
                onHide={() => { setsubsShow(false) }}
                aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton >
                    <h1 className="new-pophead" >Subscriptions Globality</h1>
                </Modal.Header>
                <Modal.Body >
                    <div class="globality">
                        <div class="form-group position-relative">
                            <input
                                type="text"
                                class="form-control serch-input"
                                placeholder="Search"
                                onChange={event => searchCountry(event.target.value)}
                            />
                            <img src="images/search-img.png" class="search-img" alt="/" />
                        </div>
                        <div class="country-globe-ranks">
                            <div class="row align-items-center">
                                {
                                    country?.length != 0 ? country?.map((item, key) =>
                                    (
                                        <>
                                            <div class="col-md-8">
                                                <div class="country-ranks">
                                                    <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                    <h2>{item?.country}</h2>
                                                </div>
                                            </div>
                                            <div class="col-md-4 text-right">
                                                <div class="ranks">
                                                    <h1>{item?.subscriptionCount}</h1>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    ) : <>
                                        <div class="col-md-8">
                                            <div class="country-ranks">
                                                <h2>Data not found</h2>
                                            </div>
                                        </div>

                                    </>
                                }
                            </div>
                        </div>
                    </div>



                </Modal.Body>
            </Modal> */}
    </>
  );
}

export default Dashboard;
